import { HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';

import { ApiActionModifier } from '@sulser-print/constants/api-action-modifiers';
import { ApiAction } from '@sulser-print/constants/api-actions';
import { ApiModule } from '@sulser-print/constants/api-modules/api-modules';
import { ApiParameter } from '@sulser-print/constants/parameters';
import { Directory } from '@sulser-print/models/directory/directory';
import { PaginatedData } from '@sulser-print/models/paginated-response';
import { PrintJob } from '@sulser-print/models/print-job/print-job';
import { PrintJobItem } from '@sulser-print/models/print-job/print-job-item';
import { SimplePrintJob } from '@sulser-print/models/print-job/simple-print-job';
import { Project } from '@sulser-print/models/project/project';
import { Response } from '@sulser-print/models/response';
import { tap } from 'rxjs';

import { PrintJobsStore } from './print-jobs.store';
import { RequestService } from './request.service';

@Injectable({ providedIn: 'any' })
export class PrintJobsService extends RequestService {
	private readonly endPoint = ApiModule.PRINT_JOBS;

	printJobsStore = inject(PrintJobsStore, { optional: true });

	clone(printJob: PrintJob) {
		return this.httpClient.post<Response<PrintJob>>(`${this.endPoint}/${ApiAction.CLONE}`, printJob);
	}

	delete(printJobId: PrintJob['id']) {
		return this.httpClient.delete<Response<null>>(`${this.endPoint}/${ApiAction.DELETE}/${printJobId}`).pipe(
			tap(() => {
				if (printJobId) {
					this.printJobsStore?.removePrintJobs([printJobId]);
				}
			}),
		);
	}

	deletePrintJobItems(printJobId: PrintJob['id'], itemIds: Array<PrintJobItem['id']>) {
		return this.httpClient.delete<Response<null>>(`${this.endPoint}/${ApiAction.REMOVE}/${printJobId}`, {
			body: itemIds,
		});
	}

	downloadDeliveryNote(printJobId: PrintJob['id']) {
		return this.httpClient.post(`${this.endPoint}/${ApiAction.DOWNLOAD}/${printJobId}/${ApiActionModifier.DELIVERY_NOTE}`, undefined, {
			responseType: 'blob',
		});
	}

	downloadFiles(printJob: PrintJob) {
		return this.httpClient.post(`${this.endPoint}/${ApiAction.DOWNLOAD}/${printJob.id}/${ApiActionModifier.FILES}`, undefined, {
			responseType: 'blob',
		});
	}

	getAll(options: Params = {}) {
		let parameter = new HttpParams();

		for (const key of Object.keys(options)) {
			if (options[key]) {
				parameter = parameter.append(key, options[key]);
			}
		}

		return this.httpClient.get<Response<PaginatedData<PrintJob>>>(`${this.endPoint}/${ApiAction.READ}`, { params: parameter });
	}

	getAllByProjectId(projectId: Project['id'], options: Params = {}) {
		let parameter = new HttpParams();

		for (const key of Object.keys(options)) {
			if (options[key]) {
				parameter = parameter.append(key, options[key]);
			}
		}

		return this.httpClient.get<Response<PaginatedData<PrintJob>>>(
			`${this.endPoint}/${ApiAction.READ}/${ApiActionModifier.ALL}/${projectId}`,
			{ params: parameter },
		);
	}

	getById(printJobId: PrintJob['id']) {
		return this.httpClient.get<Response<PrintJob>>(`${this.endPoint}/${ApiAction.READ}/${printJobId}`);
	}

	getByProjectId(projectId: Project['id']) {
		return this.httpClient.get<Response<Array<PrintJob>>>(
			`${this.endPoint}/${ApiAction.READ}/${ApiActionModifier.BY_PROJECT_ID}/${projectId}`,
		);
	}

	getProjectPrintJobIds(projectId: Project['id']) {
		return this.httpClient
			.get<
				Response<Array<SimplePrintJob>>
			>(`${this.endPoint}/${ApiAction.READ}/${ApiActionModifier.BY_PROJECT_ID}/${projectId}/item-ids`)
			.pipe(
				tap((response) => {
					this.printJobsStore?.setPrintJobs(response.data ?? []);
				}),
			);
	}

	markAsPrinted(printJob: PrintJob) {
		return this.httpClient.put<Response<boolean>>(`${this.endPoint}/${ApiAction.MARK}/${printJob.id}`, printJob);
	}

	notifyForOrderedPrintJobs(printJobs: Array<PrintJob>) {
		return this.httpClient.post<Response<null>>(`${this.endPoint}/${ApiAction.ORDER}/${ApiAction.NOTIFY}`, printJobs);
	}

	print(projectId: Project['id'], directoryIds: Array<Directory['id']>) {
		const parameters = new HttpParams({
			fromObject: { [ApiParameter.PROJECT_ID]: projectId },
		});

		const url = `${this.endPoint}/${ApiAction.ADD}`;
		return this.httpClient.post<Response<Array<SimplePrintJob>>>(url, directoryIds, { params: parameters }).pipe(
			tap((response) => {
				if (response.data) {
					this.printJobsStore?.addPrintJobs(response.data);
				}
			}),
		);
	}

	submitPrintingOrderFromPrintJobs(value: Array<PrintJob>) {
		return this.httpClient.post<Response<Array<PrintJob>>>(`${this.endPoint}/${ApiAction.ORDER}`, value);
	}
}
