import { Injectable, signal } from '@angular/core';

import { Directory } from '@sulser-print/models/directory/directory';
import { PrintJobItem } from '@sulser-print/models/print-job/print-job-item';
import { SimplePrintJob } from '@sulser-print/models/print-job/simple-print-job';

@Injectable()
export class PrintJobsStore {
	printJobs = signal<Array<SimplePrintJob>>([]);

	addPrintJob(printJob: SimplePrintJob) {
		if (this.directoryPrintJobExists(printJob.fileId)) {
			return;
		}

		this.printJobs.update((printJobs) => [...printJobs, printJob]);
	}

	addPrintJobs(printJobs: Array<SimplePrintJob>) {
		this.printJobs.update((existingPrintJobs) => [...existingPrintJobs, ...printJobs]);
	}

	directoryPrintJobExists(directoryId: Directory['id']) {
		return this.printJobs().some((pj) => pj.fileId === directoryId);
	}

	removePrintJobs(itemIds: Array<PrintJobItem['id']>) {
		this.printJobs.update((printJobs) => printJobs.filter((pj) => !itemIds.includes(pj.id)));
	}

	setPrintJobs(printJobs: Array<SimplePrintJob>) {
		this.printJobs.set(printJobs);
	}
}
